import type { IconProps } from 'react-feather';

export const ProfilesIcon = ({ size = 24, className = '', color = 'currentColor' }: IconProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <g>
      <g>
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M2.25 3.0006C2.24997 2.58639 2.58573 2.25057 2.99994 2.25054L9.74994 2.25C10.1642 2.24997 10.5 2.58573 10.5 2.99994C10.5 3.41415 10.1643 3.74997 9.75006 3.75L4.81072 3.7504L9.21919 8.15815C9.45908 8.05633 9.72296 8 10 8H14C14.2964 8 14.5777 8.06446 14.8307 8.18012L19.2611 3.7504L14.3218 3.75C13.9075 3.74997 13.5718 3.41415 13.5718 2.99994C13.5719 2.58573 13.9077 2.24997 14.3219 2.25L21.0719 2.25054C21.4861 2.25057 21.8219 2.58639 21.8218 3.0006L21.8213 9.7506C21.8212 10.1648 21.4854 10.5006 21.0712 10.5005C20.657 10.5005 20.3212 10.1647 20.3213 9.75048L20.3217 4.81114L15.8625 9.26965C15.9512 9.4959 16 9.74226 16 10V14C16 14.2769 15.9437 14.5407 15.842 14.7805L20.3217 19.2595L20.3213 14.3202C20.3212 13.9059 20.657 13.5701 21.0712 13.5701C21.4854 13.5701 21.8212 13.9058 21.8213 14.32L21.8218 21.07C21.8219 21.4843 21.4861 21.8201 21.0719 21.8201L14.3219 21.8206C13.9077 21.8207 13.5719 21.4849 13.5718 21.0707C13.5718 20.6565 13.9075 20.3207 14.3218 20.3206L19.2611 20.3202L14.7816 15.8415C14.5415 15.9435 14.2774 16 14 16H10C9.74214 16 9.49568 15.9512 9.26934 15.8623L4.81072 20.3202L9.75006 20.3206C10.1643 20.3207 10.5 20.6565 10.5 21.0707C10.5 21.4849 10.1642 21.8207 9.74994 21.8206L2.99994 21.8201C2.58573 21.8201 2.24997 21.4843 2.25 21.07L2.25054 14.32C2.25057 13.9058 2.58639 13.5701 3.0006 13.5701C3.41481 13.5701 3.75057 13.9059 3.75054 14.3202L3.75015 19.2595L8.17999 14.8304C8.06441 14.5774 8 14.2962 8 14V10C8 9.72276 8.05641 9.4587 8.15838 9.21867L3.75015 4.81114L3.75054 9.75048C3.75057 10.1647 3.41481 10.5005 3.0006 10.5005C2.58639 10.5006 2.25057 10.1648 2.25054 9.7506L2.25 3.0006ZM10 9.8H14C14.1105 9.8 14.2 9.88954 14.2 10V14C14.2 14.1105 14.1105 14.2 14 14.2H10C9.88954 14.2 9.8 14.1105 9.8 14V10C9.8 9.88954 9.88954 9.8 10 9.8Z"
          fill={color}
        />
      </g>
    </g>
  </svg>
);
